<template>
  <div>
    <nav class="navbar navbar-light bg-light fixed-top">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">
          <img src="@/assets/images/L.ogo_Data.png" alt="Logo de Datajutsu" width="100" height="50">
        </a>
        <div class="social-icons">
          <a href="https://www.facebook.com" target="_blank" class="text-dark mx-2">
            <i class="fi fi-br-digging"></i>
          </a>
          <a href="https://www.instagram.com" target="_blank" class="text-dark mx-2">
            <i class="fi fi-br-digging"></i>
          </a>
        </div>
      </div>
    </nav>

    <div id="main-content" class="container text-center">
      <div class="contenedor mt-4">
        <h2 class="mt-3">¡En Construcción!</h2>
        <div class="alert alert-secondary mt-4" role="alert">
          <i class="fi fi-br-digging"></i>
          <p>Nuestro sitio web está en construcción.<br />Mantente atento a los próximos eventos y cursos de Datajutsu.</p>
          <i class="fi fi-br-build"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style scoped>
body {
  font-family: 'Raleway', sans-serif; /* Usando directamente el nombre de la fuente importada */
  color: rgba(153, 153, 153, 255);
  background-image: url('@/assets/images/background.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  margin: 0;
  padding-top: 56px;
}
/* Otros estilos relacionados exclusivamente con App.vue pueden añadirse aquí */
</style>
